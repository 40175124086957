<template>
  <div>
    <v-card
      flat
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">SEARCH EMPLOYEE</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="mt-6">
        <v-layout column wrap class="align-center mx-2 ">
          <v-layout row wrap>
            <v-flex xs12 md3>
              <v-layout row wrap class="mx-2 mt-2">
                <v-flex xs12 md12>
                  <v-select
                    v-model="selection"
                    class="mx-2"
                    dense
                    label="Selection"
                    :items="['Active','Temporary Deactivated','Permanent Deactivated']"
                    @change="selected"
                  ></v-select>
                </v-flex>
                <v-flex xs12 md12>
                  <v-select
                    v-model="category_id"
                    class="mx-2"
                    dense
                    label="Category"
                    :items="category_items"
                    item-text="category"
                    item-value="id"
                    @change="selected"
                  ></v-select>
                </v-flex>
                <v-flex xs12 md12>
                  <v-select
                    v-model="search_by"
                    class="mx-2"
                    dense
                    label="Search By"
                    :items="['Name','Branch','Account #']"
                    @change="selected"
                  ></v-select>
                </v-flex>
                <v-flex xs12 md12 v-if="search_by!=''">
                  <v-autocomplete
                    dense
                    outlined
                    v-model="search"
                    :loading="isLoading"
                    @keyup.enter="searching($event.target.value)"
                    label="Search"
                    hide-no-data
                    placeholder="Press Enter to Search "
                    :prepend-icon="icons.mdiAccountSearchOutline"
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12 md12 v-if="search_items.length>0">
                  <v-data-table dense
                                :headers="headers"
                                height="600"
                                :items="search_items"
                                disable-pagination
                                hide-default-footer
                  >
                    <template v-slot:item="{ item }">
                      <tr
                        @click="get_search_items_info(item)"
                        :class="{'info white--text': item.id===selectedDepositId}"
                      >
                        <td>
                          {{ item.name
                          }}
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 md9>
              <v-layout row wrap class="mx-2 mt-2">
                <EmployeeDetails
                  v-on:data="on_update_status_employee"
                  :keeyyyy="keeyyyy"
                  :position="position"
                  :member_information="member_information"
                  :compensation_histories="compensation_histories"
                  :memo_history="memo_history"
                  :change_history="change_history"
                  :cropped="cropped"
                  :is_personal="false"
                ></EmployeeDetails>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import {
    mdiAccountOutline, mdiInformationOutline, mdiAccountCashOutline
    , mdiCash, mdiCellphone, mdiClipboardClockOutline, mdiContentSaveMoveOutline,
    mdiCashRefund, mdiShieldAccount, mdiGoogleCirclesCommunities, mdiBallotOutline,
    mdiCharity, mdiHandshakeOutline, mdiCubeScan, mdiGift,
  } from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import EmployeeDetails
    from '@/views/file_interface/search/components_emloyee/tabs/EmployeeDetails'
  import Account from '@/views/file_interface/search/components_emloyee/PersonalInformation'
  import Foundation from '@/views/file_interface/search/components_emloyee/EmploymentStatus'
  import DistrictClub from '@/views/file_interface/search/components_emloyee/CompensationHistory'
  import Donation from '@/views/file_interface/search/components_emloyee/MemoHistory'
  import Participation from '@/views/file_interface/search/components_emloyee/ChangeHistory'

  export default {
    components: {
      EmployeeDetails,
      Account,
      Foundation,
      DistrictClub,
      Donation,
      Participation,
    },
    setup() {
      return {
        avatarImg: require('@/assets/images/avatars/1.png'),
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiCash,
          mdiCellphone,
          mdiClipboardClockOutline,
          mdiContentSaveMoveOutline,
          mdiCashRefund,
          mdiShieldAccount,
          mdiGoogleCirclesCommunities,
          mdiBallotOutline,
          mdiCharity,
          mdiHandshakeOutline,
          mdiCubeScan,
          mdiGift,
        },
      }
    },
    data() {
      return {
        keeyyyy: 0,
        search: '',
        search_items: [],
        isLoading: false,

        selectedDepositId: -1,
        selection: '',
        search_by: '',
        category_id: '',
        category_items: [],

        cropped: this.avatarImg,
        branch: '',
        member_information: {category: '', branch: '', employee_position: ''},
        compensation_histories: [],
        memo_history: [],
        change_history: [],
        headers: [
          {text: 'Details', value: 'name', sortable: false},
        ],
        tab: '',
        tabs: [
          {title: 'EMPLOYMENT STATUS', icon: mdiGoogleCirclesCommunities},
          {title: 'PERSONAL INFORMATIONS', icon: mdiAccountOutline},
          {title: 'COMPENSATION HISTORY', icon: mdiCharity},
          {title: 'MEMO HISTORY', icon: mdiGift},
          {title: 'CHANGE HISTORY', icon: mdiHandshakeOutline},
        ],
      }
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['position']),
    },
    methods: {
      ...mapActions('employee', ['search_all_by_status_employee',]),
      ...mapActions('request_particulars', ['initialize_new_particulars',]),
      initialize_data() {
        this.initialize_new_particulars()
          .then(response => {
            this.category_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      async searching(value) {
        this.name = ''
        this.branch = ''
        this.employee_id = 0

        this.isLoading = true
        await this.search_all_by_status_employee({
          search_word: value,
          category_id: this.category_id,
          status: this.selection,
          search_by: this.search_by,
        })
          .then(response => {
            this.search_items = response.data
            this.isLoading = false
          })
      },
      selected() {
        this.selectedDepositId = -1
        this.search = ''
        this.search_items = []
        this.isLoading = false
        this.member_information = {category: '', branch: '', employee_position: ''}
        this.compensation_histories = []
        this.memo_history = []
        this.change_history = []
      },
      get_search_items_info(value) {
        this.keeyyyy++
        this.selectedDepositId = value.id
        this.employee_id = value.id
        this.member_information = value.details
        this.compensation_histories = value.details.compensation_history
        this.memo_history = value.details.memo_history
        this.change_history = value.details.change_history
        this.cropped = value.image != 'http://127.0.0.1:8000/storage' ? value.image : this.avatarImg
      },
      async on_update_status_employee() {
        await this.selected()
        await this.searching('')
      },
    }
  }
</script>
